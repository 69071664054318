import React, { Component } from "react";

import Validation from "../../FormValidation";

import "./EmailInput.css";

class EmailInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {}
    };
  }

  _updateForm(e) {
    var obj = {};
    obj[e.target.name] = e.target.value;
    this.setState(
      {
        form: Object.assign({}, this.state.form, obj)
      },
      () => {
        this.props.onChange(this.state.form);
      }
    );
  }

  render() {
    return (
      <div className="emailInput">
        <label>
          <span>Email</span>
          <Validation.components.Input
            type="text"
            name="email"
            className="field"
            placeholder="Email"
            validations={["required", "email"]}
            errorClassName="invalid-input"
            value={this.state.form.email || ""}
            onChange={this._updateForm.bind(this)}
          />
        </label>
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

export default EmailInput;
