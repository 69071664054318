import React, { Component } from "react";

import Config from "../../_config";

import "./BrandAssets.css";

class BrandAssets extends Component {
  _renderLogo() {
    if (Config.winery_logo === "") {
      return null;
    }
    return (
      <div className="brandLogo">
        <img src={Config.winery_logo} width="250" />
      </div>
    );
  }

  render() {
    return (
      <div className="brandContainer">
        {this._renderLogo()}
        <div className="brandDetails">
          <h2>{Config.sample_name}</h2>
          <h3>Presented by OkanaganWine.club</h3>
          <div className="sampleImage">
            <img src={Config.sample_logo} width="100%" />
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: decodeURIComponent(Config.sample_description)
            }}
          />
        </div>
      </div>
    );
  }
}

export default BrandAssets;
