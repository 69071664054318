import React, { Component } from 'react';

import './SafetyBadges.css';

class SafetyBadges extends Component {

  render() {

    return null;

    return (
      <div className="safetyBadges">
        <p>Security Badges</p>
        <p>Guarantee Badges</p>
      </div>
    );
  }
}

export default SafetyBadges;
