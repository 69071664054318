import React from "react";

import ErrorNotification from "../../Error/Error";

const OntarioNotAllowed = props => {
  const { details } = props;

  if (details.selected_shipping === "billing") {
    if (
      details.billing_province === "ON" ||
      details.billing_province === "NU"
    ) {
      return <NotAllowedNotification />;
    }
  } else {
    if (
      details.shipping_province === "ON" ||
      details.billing_province === "NU"
    ) {
      return <NotAllowedNotification />;
    }
  }

  return props.children;
};

const NotAllowedNotification = props => {
  return (
    <ErrorNotification message="Unfortunately the winery is unable to ship wine to your destination." />
  );
};

export default OntarioNotAllowed;
