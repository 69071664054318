import React, { Component } from 'react';

import Config from '../../../_config';

import './QuantitySelector.css';

class QuantitySelector extends Component {

  constructor(props){
    super(props);
    this.state = {
      quantity: 1,
      showReset: false
    };
  }

  //<input className="field" style={{width:"11%",marginLeft:78,float:"left"}} value={this.state.quantity} onChange={(e) => {this.setState({quantity: e.target.value})}} />

  _increaseQuantity(e){
    e.preventDefault();

    var newQuantity = this.state.quantity + 1;
    this.setState({
      quantity: newQuantity
    });

    this.props.onQuantityChange(newQuantity);

  }

  _decreaseQuantity(e){
    e.preventDefault();

    if(this.state.quantity < 2){
      return;
    }

    var newQuantity = this.state.quantity - 1;
    this.setState({
      quantity: newQuantity
    });

    this.props.onQuantityChange(newQuantity);
  }

  _changeQuantity(e){
    var v = e.target.value;

    if((!isNaN(parseFloat(v)) && isFinite(v)) || v === ""){
      this.setState({
        quantity: Number(v)
      });

      this.props.onQuantityChange(v);
    }
  }

  _renderReset(){
    if(!this.state.showReset){
      return;
    }

    return (
      <p className="cartReset">
        <a href="#" onClick={this._resetCart.bind(this)}>reset shopping cart</a>
      </p>
    )
  }

  _renderAdditionalPacksHint(){
    return (
      <label>
        {Config.additional_packs_hint}
      </label>
    )
  }

  _renderAdditionalPacksButton(){
    return (
      <button onClick={this._increaseQuantity.bind(this)}>{Config.additional_packs_button}</button>
    )
  }

  render() {
    return (
      <div className="field">
      <div className="quantitySelector">
        {/*<div>
          <label>
            <span>Quantity</span>
            <span className="field">{this.state.quantity}</span>
          </label>
        </div>*/}
        <div className="quantityChewy" style={{clear:"both"}}>
          <button onClick={this._decreaseQuantity.bind(this)}>-</button>
          <input
            type="text"
            value={this.state.quantity}
            onChange={this._changeQuantity.bind(this)}
          />
          <button onClick={this._increaseQuantity.bind(this)}>+</button>
        </div>
      </div>
      </div>
    );
  }
}

export default QuantitySelector;
