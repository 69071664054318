import React, { Component } from "react";

import { injectStripe } from "react-stripe-elements";

import moment from "moment";

import CardSection from "./CardSection";
import OrderSummary from "./OrderSummary";
import UserInformation from "./UserInformation";
import EmailInput from "./EmailInput";
import BillingInformation from "./BillingInformation";
import ShippingInformation from "./ShippingInformation";
import SpecialInstructions from "./SpecialInstructions";
import TermsAndConditions from "./TermsAndConditions";
import OntarioNotAllowed from "./OntarioNotAllowed";

import Config from "../../../_config";

import Validation from "../../FormValidation";

import axios from "axios";
import _ from "underscore";

import { API_ENDPOINT } from "../../../constants";

import "./CheckoutForm.css";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormSubmitted: false,
      isTermsChecked: false,
      data: {
        quantity: 1,
        special_instructions: ""
      }
    };
  }

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    this.setState({
      isFormSubmitted: true
    });

    var validationResults = this.form.validateAll();

    if (Object.keys(validationResults).length > 0) {
      // Invalid form.

      this.props.onError("Please fill out all information.");

      return;
    }

    if (this.state.data.quantity < 1) {
      this.props.onError("Invalid quantity.");
      return;
    }

    var details = this.state.data;

    if (Config.require_birthdate) {
      // Verify over 18.
      var yearDiff = moment().diff(
        moment(
          details.birthYear + details.birthMonth + details.birthDay,
          "YYYYMMDD"
        ),
        "years"
      );
      if (yearDiff < 19) {
        this.props.onError("You must be older than 18 to make a purchase.");
        return;
      }
    }

    /**
     * If we're shipping to the billing address, clone it to the shipping address.
     */
    if (details.selected_shipping === "billing") {
      var shippingDetails = {
        shipping_first_name: details.first_name,
        shipping_last_name: details.last_name,
        shipping_phone: details.phone,
        shipping_address: details.billing_address,
        shipping_city: details.billing_city,
        shipping_province: details.billing_province,
        shipping_postalcode: details.billing_postalcode
      };
      details = _.extend(details, shippingDetails);
    } else if (details.selected_shipping === "pickup") {
      var currentSpecialInstructions = "";
      if (details.special_instructions) {
        currentSpecialInstructions =
          details.special_instructions + "\n\n" + "PICKUP @ WINERY";
      } else {
        currentSpecialInstructions = "PICKUP @ WINERY";
      }

      var shippingDetails = {
        shipping_first_name: "PICKUP",
        shipping_last_name: "WINERY",
        shipping_address: "Winery Pickup",
        shipping_city: "Okanagan",
        shipping_province: "BC",
        shipping_postalcode: "X0X0X0",
        special_instructions: currentSpecialInstructions
      };
      details = _.extend(details, shippingDetails);
    }

    // If in test mode, prepend the special instructions with a note.
    if (Config.test_mode) {
      details = _.extend(details, {
        special_instructions:
          "THIS ORDER WAS MADE IN TEST MODE. THE USER WAS NOT CHARGED. \r\r" +
          details.special_instructions
      });
    }

    // Request token from Stripe
    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.

    /*
    var details = {
      first_name: "David",
      last_name: "Jeffries",
      phone: "6046135608",
      email: "david.jeffries@gmail.com",
      billing_address: "1111 marinaside cr #1502",
      billing_city: "Vancouver",
      billing_province: "BC",
      billing_postalcode: "V6Z2Y3",
      shipping_provider
      selected_shipping: "new",
      shipping_first_name: "Navleen",
      shipping_last_name: "Nirmal",
      shipping_phone: "2506194177",
      shipping_address: "1099 Marinaside #1103",
      shipping_city: "Vancouver",
      shipping_province: "BC",
      shipping_postalcode: "V6Z2Y3",
      special_instructions: "Special note",
      quantity:1
    };
    */

    window.fbq("track", "InitiateCheckout");

    this.props.onCheckoutProcessing("Processing Order...");

    var _t = this;

    axios
      .get(API_ENDPOINT + "/email_validation?email=" + details.email)
      .then(response => {
        if (!response.data.valid) {
          // Error with email address.
          this.props.onError("Email [" + details.email + "] is invalid.");
          return;
        }

        // Good to go.

        this.props.stripe
          .createToken({
            name: details.first_name + " " + details.last_name,
            address_line1: details.billing_address,
            address_city: details.billing_city,
            address_state: details.billing_province,
            address_zip: details.billing_postalcode,
            address_country: "CA"
          })
          .then(({ token }) => {
            if (!token.id) {
              _t.props.onError(
                "Error validating credit card. Please try again."
              );
              return;
            }

            axios
              .post(API_ENDPOINT + "/checkout", {
                test: Config.test_mode,
                token: token.id,
                user_details: details,
                order: {
                  quantity: details.quantity,
                  // 0 out the shipping cost if the user is picking it up from the winery.
                  shipping: this._calculateShippingCost(),
                  price: Number(Config.sample_price),
                  deposit: Number(Config.sample_deposit),
                  product: Config.sample_name,
                  variant_id: Config.product_variant_id,
                  vendor: Config.winery_name
                }
              })
              .then(function(response) {
                if (response.data.error) {
                  _t.props.onError(response.data.message);
                } else {
                  // Pass a confirmation number to complete function.

                  window.fbq("track", "Purchase", {
                    value:
                      (Number(Config.sample_price) +
                        Number(Config.sample_deposit)) *
                      details.quantity,
                    currency: "CAD"
                  });

                  _t.props.onCheckoutComplete(response.data.order.name);
                }
              })
              .catch(function(error) {
                _t.props.onError(
                  "There was an error processing your request. Please try again or contact " +
                    Config.winery_name +
                    " for assistance."
                );
              });
          })
          .catch(function(error) {
            _t.props.onError(
              "Error with credit card information. Please check and try again"
            );
          });
      });
  };

  /**
   * Update the state data when input changes on each user-input component.
   */
  _updateData(data) {
    this.setState({
      data: Object.assign({}, this.state.data, data)
    });
  }

  /**
   * Calculate the shipping cost for the number of packs selected.
   */
  _calculateShippingCost() {
    if (this.state.data.selected_shipping === "pickup") {
      return 0;
    }

    var shippingOverride = null;

    if (
      this.state.data.billing_province &&
      this.state.data.selected_shipping === "billing"
    ) {
      // Use the billing province to see if there's a shipping override.
      if (Config.sample_shipping_province[this.state.data.billing_province]) {
        shippingOverride =
          Config.sample_shipping_province[this.state.data.billing_province];
      }
    }

    if (
      this.state.data.shipping_province &&
      this.state.data.selected_shipping === "new"
    ) {
      // Use the shipping address to see if there's a shipping override.
      if (Config.sample_shipping_province[this.state.data.shipping_province]) {
        shippingOverride =
          Config.sample_shipping_province[this.state.data.shipping_province];
      }
    }

    var shippingCost = Number(Config.sample_shipping);

    if (shippingOverride) {
      shippingCost = shippingOverride;
    }

    if (Config.free_shipping_for_multiple && this.state.data.quantity > 1) {
      return 0;
    }

    if (!Config.sample_shipping_extra_items) {
      // 2 packs ship for the price of 1.
      var shippingMultiplier;
      shippingMultiplier = Math.ceil(this.state.data.quantity / 2);
      return shippingCost * shippingMultiplier;
    } else {
      // Add shipping for extra orders.
      return (
        shippingCost +
        (this.state.data.quantity - 1) * Config.sample_shipping_extra_items
      );
    }
  }

  render() {
    // See if the shipping is calculated so we know when to display it on the summary.
    let shippingIsCalculated = false;

    if (this.state.data.selected_shipping === "pickup") {
      shippingIsCalculated = true;
    }

    if (
      this.state.data.billing_province &&
      this.state.data.selected_shipping === "billing"
    ) {
      shippingIsCalculated = true;
    }

    if (
      this.state.data.shipping_province &&
      this.state.data.selected_shipping === "new"
    ) {
      shippingIsCalculated = true;
    }

    return (
      <Validation.components.Form
        ref={c => {
          this.form = c;
        }}
        onSubmit={this.handleSubmit.bind(this)}
        className="checkoutForm"
      >
        <h3>Your Information</h3>
        <UserInformation
          hideBirthday={!Config.require_birthdate}
          onChange={this._updateData.bind(this)}
        />
        <EmailInput onChange={this._updateData.bind(this)} />
        <BillingInformation onChange={this._updateData.bind(this)} />
        <CardSection />
        <ShippingInformation onChange={this._updateData.bind(this)} />
        {/*<SpecialInstructions onChange={this._updateData.bind(this)} />*/}
        <hr />
        <OntarioNotAllowed details={this.state.data}>
          <div>
            <OrderSummary
              shippingIsCalculated={shippingIsCalculated}
              shippingCost={this._calculateShippingCost()}
              onChange={this._updateData.bind(this)}
            />

            <TermsAndConditions
              onCheckChange={value => {
                this.setState({
                  isTermsChecked: value
                });
              }}
              renderCheckWarning={
                !this.state.isTermsChecked && this.state.isFormSubmitted
              }
            />
            <button>Place Order</button>
            <p>
              Your order is being processed directly by {Config.winery_name}.
              Your credit card statement will show a charge from{" "}
              {Config.winery_name} within 3 business days.
            </p>
          </div>
        </OntarioNotAllowed>
        <div style={{ clear: "both" }} />
      </Validation.components.Form>
    );
  }
}

export default injectStripe(CheckoutForm);
