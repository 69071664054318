import React, { Component } from "react";

import Config from "../../../_config";

import "./OrderSummary.css";

import QuantitySelector from "./QuantitySelector";
import SafetyBadges from "./SafetyBadges";

import { GST_RATE, PST_RATE } from "../../../constants";

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 1
    };
  }

  _getShippingCost() {
    return this.props.shippingCost;
  }

  _renderTotalPrice() {
    var shippingCost = this._getShippingCost();

    var gstCost = this._getGST();

    var total = this._getPackPrice() * this.state.quantity + shippingCost;

    if (Config.show_tax_line) {
      // add the GST to the total
      total = total + this._getGST();
    }

    return total.toFixed(2);
  }

  /**
   * Return a shipping cost or "FREE" if its 0.
   */
  _renderShippingCost() {
    if (this.props.shippingIsCalculated === false) {
      return <small>TBD</small>;
    }

    var s = this._getShippingCost();
    if (Number(s) < 0.01) {
      return "FREE";
    }
    return "$" + s.toFixed(2);
  }

  _getGST() {
    return (
      Number(Config.sample_price) * this.state.quantity * (GST_RATE + PST_RATE)
    );
  }

  /**
   * Return the pack price.
   * If configured to not show a GST line, include the GST.
   */
  _getPackPrice() {
    var price = Number(Config.sample_price);

    if (!Config.show_tax_line) {
      price =
        Number(Config.sample_price) +
        Number(Config.sample_price) * (GST_RATE + PST_RATE);
    }

    return round(price + Number(Config.sample_deposit), 2);
  }

  _renderGst() {
    if (!Config.show_tax_line) {
      return null;
    }

    return (
      <div>
        <label>
          <span>Tax (PST & GST)</span>
          <span className="field">${this._getGST().toFixed(2)}</span>
        </label>
      </div>
    );
  }

  _renderShippingLine() {
    if (this.props.noShippingCharge) {
      return null;
    }
    return (
      <div style={{ clear: "both" }}>
        <label>
          <span>Shipping</span>
          <span className="field">{this._renderShippingCost()}</span>
        </label>
      </div>
    );
  }

  /*
   * If tax is not broken down, add a blurb saying the tax is included with the pack price.
   */
  _renderTaxIncluded() {
    if (this.props.show_tax_line) {
      // Dont print anything if the tax is on its own line.
      return null;
    }

    return <span>(taxes included)</span>;
  }

  render() {
    return (
      <div className="orderSummaryWrapper">
        <SafetyBadges />
        <div>
          <div>
            <h3>Order Summary</h3>
            <h4>{Config.sample_name}</h4>
            <h4>
              ${this._getPackPrice().toFixed(2)} / pack{" "}
              {this._renderTaxIncluded()}
            </h4>
            <br />
            <br />
            {/*<label>
              <span>Your Order</span>
              <span className="field">
                <p>{this.state.quantity} sample pack{this.state.quantity > 1 ? 's' : ''} @ ${this._getPackPrice()}</p>
              </span>
            </label>*/}
          </div>
          <label>
            <span>Quantity</span>
            <QuantitySelector
              onQuantityChange={newQuantity => {
                this.props.onChange({ quantity: newQuantity });
                this.setState({
                  quantity: newQuantity
                });
              }}
            />
          </label>
          {this._renderGst()}
          {this._renderShippingLine()}
          <div className="OrderSummaryWrapper">
            <label>
              <span>Total</span>
              <span className="field">${this._renderTotalPrice()}</span>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderSummary;
