import React, { Component } from "react";

import "./InfoNotification.css";

class InfoNotification extends Component {
  render() {
    if (!this.props.message) {
      return null;
    }

    return (
      <div className="infoContainer">
        <span>Note: </span>
        {this.props.message}
      </div>
    );
  }
}

export default InfoNotification;
