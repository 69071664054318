import React, { Component } from "react";

import { Grid, Row, Col } from "react-bootstrap";

import "./OrderComplete.css";

import Config from "../../_config";

const { detect } = require("detect-browser");

class OrderComplete extends Component {
  componentDidMount() {
    (function(s, u, m, o, j, v) {
      j = u.createElement(m);
      v = u.getElementsByTagName(m)[0];
      j.async = 1;
      j.src = o;
      j.dataset.sumoSiteId =
        "78c14a42217e9e4f5c66cd26eb49812a69a3fbbc6f6467e713c3ae1aab4d020b";
      v.parentNode.insertBefore(j, v);
    })(window, document, "script", "//load.sumo.com/");
  }

  _renderVimeo() {
    if (!Config.vimeo) {
      return null;
    }
    return (
      <div>
        <br />
        <span>
          Finally, watch the quick thank you video from {Config.winery_name}{" "}
          below:
        </span>
        <br />
        <br />
        <div className="videoWrapper">
          <iframe src={Config.vimeo} style={{ border: 0 }} />
        </div>
      </div>
    );
  }

  render() {
    const browser = detect();
    var addIEStyle = {};
    if (browser) {
      if (browser.name === "ie") {
        addIEStyle = {
          width: 1000
        };
      }
    }

    return (
      <Grid>
        <Row className="justify-content-center" style={addIEStyle}>
          <Col
            xs={12}
            sm={12}
            md={10}
            lg={10}
            mdOffset={1}
            lgOffset={3}
            className="checkoutContainer"
            style={addIEStyle}
          >
            <div className="orderCompleteContainer">
              <h1>Woo! Your new wines will ship soon!</h1>
              <h2>A couple important notes before you go...</h2>

              <br />
              <br />
              <span>
                Your order number with {Config.winery_name} is{" "}
                {this.props.confirmationNumber || "#0000"}. This is important
                for customer service. You'll be emailed with a tracking number
                as soon as {Config.winery_name} ships it! Get ready for some
                amazing wines :)
              </span>
              <br />
              <br />
              <span>
                You can download the PDF of the wine selection, tasting notes,
                pairings early by{" "}
                <a href={Config.wine_pdf} target="_blank">
                  clicking here
                </a>
                . You'll still get the free hard copy in your shipment!
              </span>

              <br />
              <br />
              <span>
                If this is your first purchase, make sure to join our Private{" "}
                <a
                  href="https://www.facebook.com/groups/1612301825712955/"
                  target="_blank"
                >
                  VIP Members group on Facebook
                </a>
              </span>

              <br />
              <br />
              {/*<div className="shareBlock">
                <h2>Share With Your Friends!</h2>
                <span>
                  Trying new wines with friends is always more fun, send a
                  message to your favorite wine drinking friends so you can
                  compare notes and try the same wines.
                </span>
              </div>*/}
              {this._renderVimeo()}
              <br />
              <span>
                If you have any questions or issues please email{" "}
                <a href="mailto:hello@okanaganwine.club">
                  hello@okanaganwine.club
                </a>{" "}
                and we'll make sure it gets sorted out right away!{" "}
              </span>
              <br />
              <br />
              <span>Thanks again, and talk soon!</span>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default OrderComplete;
