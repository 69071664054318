import React, { Component } from "react";

import Validation from "../../FormValidation";

class AddressInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {}
    };
  }

  _updateForm(e) {
    var obj = {};
    obj[e.target.name] = e.target.value;
    this.setState(
      {
        form: Object.assign({}, this.state.form, obj)
      },
      () => {
        this.props.onChange(this.state.form);
      }
    );
  }

  render() {
    return (
      <div className="addressInformation">
        <label>
          <span>Address</span>
          <Validation.components.Input
            name="address"
            className="field"
            placeholder="Address"
            validations={["required"]}
            errorClassName="invalid-input"
            value={this.state.form.address || ""}
            onChange={this._updateForm.bind(this)}
          />
        </label>
        <div style={{ clear: "both" }} />
        <label>
          <span>City</span>
          <Validation.components.Input
            name="city"
            className="field"
            placeholder="City"
            validations={["required"]}
            errorClassName="invalid-input"
            value={this.state.form.city || ""}
            onChange={this._updateForm.bind(this)}
          />
        </label>
        <div style={{ clear: "both" }} />
        <label>
          <span>Province</span>
          <Validation.components.Select
            name="province"
            className="field"
            validations={["required"]}
            errorClassName="invalid-input"
            value={this.state.form.province || ""}
            onChange={this._updateForm.bind(this)}
          >
            <option value="">Select Province...</option>
            <option value="AB">Alberta</option>
            <option value="BC">British Columbia</option>
            <option value="MB">Manitoba</option>
            <option value="NB">New Brunswick</option>
            <option value="NL">Newfoundland and Labrador</option>
            <option value="NS">Nova Scotia</option>
            <option value="NT">Northwest Territories</option>
            <option value="NU">Nunavut</option>
            <option value="ON">Ontario</option>
            <option value="PE">Prince Edward Island</option>
            <option value="QC">Quebec</option>
            <option value="SK">Saskatchewan</option>
            <option value="YT">Yukon</option>
          </Validation.components.Select>
        </label>
        <div style={{ clear: "both" }} />
        <label>
          <span>Postal Code</span>
          <Validation.components.Input
            name="postalcode"
            className="field"
            placeholder="Postal Code"
            validations={["required"]}
            errorClassName="invalid-input"
            value={this.state.form.postalcode || ""}
            onChange={this._updateForm.bind(this)}
          />
        </label>
        <div style={{ clear: "both" }} />
      </div>
    );
  }
}

export default AddressInformation;
