import React, { Component } from "react";

import { Grid, Row, Col } from "react-bootstrap";

import TestMode from "./modules/TestMode/TestMode";
import Error from "./modules/Error/Error";
import Loading from "./modules/Loading/Loading";
import BrandAssets from "./modules/BrandAssets/BrandAssets";
import Checkout from "./modules/Checkout/Checkout";
import OrderComplete from "./modules/OrderComplete/OrderComplete";

import SaleComplete from "./modules/SaleComplete/SaleComplete";

import Config from "./_config";

import AgeModal from "./AgeModal";

import "./App.css";

const { detect } = require("detect-browser");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderComplete: false,
      confirmationNumber: null,
      loading: false,
      loadingStatus: "",
      error: null
    };
  }

  componentWillMount() {
    if (
      window.location.protocol === "http:" &&
      !Config.test_mode &&
      window.location.href.indexOf(":3000") < 0
    ) {
      window.location.href = "https://" + window.location.href.split("//")[1];
    }
  }

  _renderPage() {
    if (
      (!Config.domain || Config.domain !== window.location.host) &&
      window.location.href.startsWith("http://localhost") === false &&
      window.location.href.indexOf("netlify") < 0
    ) {
      return <span>Not available</span>;
    }

    if (
      this.state.orderComplete ||
      window.location.href.indexOf("?viewComplete=true") > 0
    ) {
      return (
        <OrderComplete confirmationNumber={this.state.confirmationNumber} />
      );
    }

    if (
      Config.sale_complete &&
      window.location.href.indexOf("override=true") < 0
    ) {
      return <SaleComplete />;
    }

    if (window.location.href === "https://winecheckout.netlify.com/") {
      return <p>Checkout will appear here when live.</p>;
    }

    // Return the checkout

    return (
      <Container>
        <TestMode />
        <Error message={this.state.error} />
        <Loading
          loading={this.state.loading}
          status={this.state.loadingStatus}
        />
        <BrandAssets />
        <Checkout
          onCheckoutProcessing={message => {
            this.setState({
              loading: true,
              loadingStatus: message
            });
          }}
          onCheckoutComplete={confirmationNumber => {
            this.setState({
              orderComplete: true,
              confirmationNumber
            });
          }}
          onError={message => {
            this.setState({
              loading: false,
              error: message
            });
            window.scrollTo(0, 0);
          }}
        />
        <AgeModal />
      </Container>
    );
  }

  render() {
    return this._renderPage();
  }
}

function Container(props) {
  var addIEStyle = {};

  const browser = detect();

  if (browser) {
    if (browser.name === "ie") {
      addIEStyle = {
        width: 1000
      };
    }
  }

  return (
    <Grid>
      <Row className="justify-content-center" style={addIEStyle}>
        <Col
          xs={12}
          sm={12}
          md={10}
          lg={7}
          mdOffset={1}
          lgOffset={3}
          className="checkoutContainer"
          style={addIEStyle}
        >
          {props.children}
        </Col>
      </Row>
    </Grid>
  );
}

export default App;
