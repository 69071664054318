import React, { Component } from "react";

import Config from "../../../_config";

import UserInformation from "./UserInformation";
import AddressInformation from "./AddressInformation";

import InfoNotification from "../../InfoNotification";

import "./ShippingInformation.css";

//const ATS_WARNING_MESSAGE = "Note: ATS does not service rural areas, P.O. boxes and small towns.";
const ATS_WARNING_MESSAGE =
  "Note: ATS does not service rural areas, P.O. boxes and small towns.";

class ShippingInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedShipping: null,
      selectedProvider: "canadapost"
    };
  }

  componentWillMount() {
    this.props.onChange({
      selected_shipping: "billing",
      shipping_provider: "canadapost"
    });
  }

  _handleShippingChange(e) {
    this.setState({
      selectedShipping: e.target.value
    });
    this.props.onChange({
      selected_shipping: e.target.value,
      shipping_address: null,
      shipping_city: null,
      shipping_first_name: null,
      shipping_last_name: null,
      shipping_phone: null,
      shipping_postalcode: null,
      shipping_province: null
    });
  }

  _handleProviderChange(e) {
    this.setState({
      selectedProvider: e.target.value
    });
    this.props.onChange({
      shipping_provider: e.target.value
    });
  }

  _renderShippingProvider() {
    if (this.state.selectedShipping === "pickup") {
      return null;
    }
    return (
      <div>
        <h3>Ship With</h3>
        <div className="shippingOptions">
          <label>
            <input
              type="radio"
              id="canadapost"
              value="canadapost"
              name="provider"
              checked={this.state.selectedProvider === "canadapost"}
              onChange={this._handleProviderChange.bind(this)}
            />{" "}
            Canada Post
          </label>
          {!Config.disable_ats ? (
            <label>
              <input
                type="radio"
                id="ats"
                value="ats"
                name="provider"
                checked={this.state.selectedProvider === "ats"}
                onChange={this._handleProviderChange.bind(this)}
              />{" "}
              ATS
            </label>
          ) : null}
        </div>
        {this.state.selectedProvider === "ats" ? (
          <InfoNotification message={ATS_WARNING_MESSAGE} />
        ) : null}
      </div>
    );
  }

  render() {
    return (
      <div className="shippingInformation">
        <h3>Ship To</h3>
        <div className="shippingOptions">
          <label>
            <input
              type="radio"
              id="billing"
              value="billing"
              name="shipping"
              checked={
                this.state.selectedShipping === null ||
                this.state.selectedShipping === "billing"
              }
              onChange={this._handleShippingChange.bind(this)}
            />{" "}
            Billing Address
          </label>
          <label>
            <input
              type="radio"
              id="new"
              value="new"
              name="shipping"
              checked={this.state.selectedShipping === "new"}
              onChange={this._handleShippingChange.bind(this)}
            />{" "}
            New Address
          </label>
          {!Config.disable_winery_pickup ? (
            <label>
              <input
                type="radio"
                id="pickup"
                value="pickup"
                name="shipping"
                checked={this.state.selectedShipping === "pickup"}
                onChange={this._handleShippingChange.bind(this)}
              />{" "}
              Pickup At Winery
            </label>
          ) : null}
        </div>
        {this.state.selectedShipping === "pickup" &&
        Config.winery_pickup_message ? (
          <InfoNotification message={Config.winery_pickup_message} />
        ) : null}
        {this.state.selectedShipping === "new" ? (
          <div>
            <h3>Shipping Address</h3>
            <UserInformation
              hideBirthday={true}
              onChange={data => {
                // Transform the user information data keys to be prefixed with shipping_{key}
                var newData = {};

                Object.keys(data).forEach(item => {
                  newData["shipping_" + item] = data[item];
                });

                this.props.onChange(newData);
              }}
            />
            <AddressInformation
              onChange={data => {
                // Transform the address information data keys to be prefixed with shipping_{key}
                var newData = {};

                Object.keys(data).forEach(item => {
                  newData["shipping_" + item] = data[item];
                });

                this.props.onChange(newData);
              }}
            />
          </div>
        ) : null}
        {this._renderShippingProvider()}
      </div>
    );
  }
}

export default ShippingInformation;
