import React, { Component } from 'react';

import './BillingInformation.css';

import AddressInformation from './AddressInformation';

class BillingInformation extends Component {

  render() {
    return (
      <div className="billingInformation">
        <h3>Billing Information</h3>
        <AddressInformation
          onChange={(data) => {
            // Transform the address information data keys to be prefixed with billing_{key}
            var newData = {};

            Object.keys(data).forEach((item) => {
                newData['billing_' + item] = data[item];
            });

            this.props.onChange(newData);
          }}
        />
      </div>
    );
  }

}

export default BillingInformation;
