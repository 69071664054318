/**
* Render a banner if test mode is enabled in the config.
*/
import React, { Component } from 'react';

import Config from '../../_config';

import './TestMode.css';

class TestMode extends Component {

  render() {

    if(!Config.test_mode){
      return null
    }

    return (
      <div className="testModeContainer">
        Test Mode Enabled! Use card 4242424242424242 to test transactions.
      </div>
    );
  }
}

export default TestMode;
