import React, { Component } from "react";

import Validation from "../../FormValidation";

import "./TermsAndConditions.css";

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false
    };
  }

  _renderCheckWarning() {
    if (!this.props.renderCheckWarning) {
      return null;
    }
    return (
      <div className="tc-error-prompt">
        You must agree to the terms & conditions.
      </div>
    );
  }

  render() {
    return (
      <div className="termsAndConditions">
        <label>
          <span>
            <Validation.components.Input
              name="termsandconditions"
              type="checkbox"
              validations={["required"]}
              errorClassName="invalid-input"
              checked={this.state.isChecked}
              onChange={e => {
                this.setState({
                  isChecked: !e.target.checked
                });
                this.props.onCheckChange(!e.target.checked);
              }}
            />
          </span>
          <span>
            I agree to the{" "}
            <a href={process.env.PUBLIC_URL + "/terms.txt"} target="_blank">
              terms & conditions
            </a>
          </span>
        </label>
        {this._renderCheckWarning()}
      </div>
    );
  }
}

export default TermsAndConditions;
