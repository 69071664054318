import React, { Component } from 'react';

import Validation from '../../FormValidation';

import './SpecialInstructions.css';

class SpecialInstructions extends Component {

  constructor(props){
    super(props);
    this.state = {
      form: {}
    };
  }

  _updateForm(e){
    var obj = {};
    obj[e.target.name] = e.target.value;
    this.setState({
      form: Object.assign({}, this.state.form, obj)
    }, () => {
      this.props.onChange(this.state.form);
    });
  }

  render() {
    return (
      <div className="specialInstructions">
        <div style={{clear:"both"}} />
        <div>
          <h3>Special Instructions</h3>
          <label>
            <textarea
              name="special_instructions"
              className="field"
              value={this.state.form.specialinstructions}
              onChange={this._updateForm.bind(this)}
            />
          </label>
        </div>
      </div>
    );
  }

}

export default SpecialInstructions;
