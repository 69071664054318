module.exports = {
              test_mode: false,
              sale_complete: false,

              domain: "sales.monstervineyards.ca",

              winery_name: "Monster Vineyards",
              winery_logo: "https://okanaganwine.club/monsterlogoblue-2/",
              sample_name: "Monster Welcome Pack",
              sample_logo: "",
              sample_description: "%3Cmeta%20charset=%22utf-8%22%3E%0A%3Cp%20class=%22p1%22%3E%3Cspan%20class=%22s1%22%3E%3Cb%3EYour%20exclusive%20Monster%20Vineyards%20Welcome%20Pack%20includes:%3C/b%3E%3C/span%3E%3C/p%3E%0A%3Cul%3E%0A%3Cli%3ERos%C3%A9%202019%20-%20$18.00%3C/li%3E%0A%3Cli%3ESkinny%20Dip%20Chardonnay%202019%C2%A0%20-%20$20.00%3C/li%3E%0A%3Cli%3ERiesling%202019%C2%A0%20-%20$20.00%3C/li%3E%0A%3Cli%3EMonster%20Cab%202016%C2%A0%20-%20$20.00%3C/li%3E%0A%3Cli%3EShiraz%202016%20-%20$20.00%3C/li%3E%0A%3Cli%3EMonster%20Brute%202019%20-%20$20.00%3C/li%3E%0A%3C/ul%3E%0A%3Cp%20class=%22p1%22%3E%3Cspan%20class=%22s1%22%3E%3Cb%3EDouble%20your%20order%20with%20no%20extra%20shipping%20fees%20for%20big%20savings!%3C/b%3E%3Cbr%3E%20%3C/span%3E%3C/p%3E",
              sample_price: "102.09",
              sample_shipping: "0.00",
              sample_deposit: "0.60",
              free_shipping_for_multiple: false,

              sample_shipping_province: {
                // Alberta
                'AB': 20,
                // British Columbia
                'BC': 15,
                // Manitoba
                'MB': 25,
                // New Brunswick
                'NB': 30,
                // Newfoundland and Labrador
                'NL': 30,
                // Nova Scotia
                'NS': 30,
                // Northwest Territories
                'NT': 30,
                // Nunavut
                'NU': 30,
                // Ontario
                'ON': null,
                // PEI
                'PE': 30,
                // Quebec
                'QC': 30,
                // Saskatchewan
                'SK': 20,
                // Yukon
                'YT': 30
              },

              product_variant_id: "32158190239830",

              stripe_key: "pk_live_51HTsFZIJNnS7qQ6SF94GNlNOXA6vJMXLAvyqvAU8zuit2CLs4ipEiaYVKlfYpzWQfvfyDIOKy5xYyx3LFrrBxMvo00UoSpXSx6",

              show_tax_line: false,

              disable_winery_pickup: false,
              require_birthdate: false,

              vimeo: "",
              wine_pdf: "https://okanaganwine.club/monster-booklet-welcome-pack-2/",
              disable_ats: false,
              winery_pickup_message: "Your Monster Wine Pack will be available for pick up at Poplar Grove Winery. 425 Middle Bench rd. North, Penticton BC, V2A 8S5. Please call (250) 493-9463 to set up your pickup. "

            };
