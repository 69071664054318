import React, { Component } from "react";
import Modal from "react-modal";

class AgeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ageError: null,
      ageConfirmed: false,
      month: "",
      day: "",
      year: ""
    };
  }

  render() {
    return (
      <Modal
        isOpen={!this.state.ageConfirmed}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)"
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)"
          }
        }}
      >
        <center>
          <h3>Please enter your date of birth</h3>
          <p>You must be 19 years of age or older to enter this site.</p>
        </center>
        <form>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "inline" }}>
              <select
                style={{ marginTop: 7, border: "1px solid #ccc", fontSize: 16 }}
                value={this.state.month}
                onChange={e => {
                  this.setState({
                    month: e.target.value
                  });
                }}
              >
                <option value="">Select month...</option>
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>
            <div style={{ display: "inline" }}>
              <input
                type="number"
                placeholder="Day"
                style={{
                  fontSize: 16,
                  width: 100,
                  textAlign: "center",
                  padding: 5,
                  border: "1px solid #ccc"
                }}
                maxLength="2"
                value={this.state.day}
                onChange={e => {
                  this.setState({
                    day: e.target.value
                  });
                }}
              />
            </div>
            <div style={{ display: "inline" }}>
              <input
                type="number"
                placeholder="Year"
                style={{
                  fontSize: 16,
                  width: 100,
                  textAlign: "center",
                  padding: 5,
                  border: "1px solid #ccc"
                }}
                maxLength="4"
                value={this.state.year}
                onChange={e => {
                  this.setState({
                    year: e.target.value
                  });
                }}
              />
            </div>
          </div>
          {this.state.ageError ? (
            <center>
              <p style={{ color: "red", fontWeight: "bold" }}>
                {this.state.ageError}
              </p>
            </center>
          ) : null}
          <div
            style={{ justifyContent: "center", display: "flex", marginTop: 25 }}
          >
            <button
              style={{ width: 250, display: "inline" }}
              onClick={e => {
                e.preventDefault();

                if (this.state.month === "") {
                  this.setState({
                    ageError: "Please select your birth month"
                  });
                  return;
                }

                if (this.state.day === "") {
                  this.setState({
                    ageError: "Please enter your birth date"
                  });
                  return;
                }

                if (this.state.year === "") {
                  this.setState({
                    ageError: "Please enter your birth year"
                  });
                  return;
                } else if (
                  this.state.year.length < 4 ||
                  this.state.year.length > 4
                ) {
                  this.setState({
                    ageError: "Please enter a valid year"
                  });
                  return;
                }

                function _calculateAge(birthday) {
                  // birthday is a date
                  var ageDifMs = Date.now() - birthday.getTime();
                  var ageDate = new Date(ageDifMs); // miliseconds from epoch
                  return Math.abs(ageDate.getUTCFullYear() - 1970);
                }

                if (
                  _calculateAge(
                    new Date(
                      this.state.year,
                      this.state.month - 1,
                      this.state.day
                    )
                  ) >= 19
                ) {
                  this.setState({
                    ageConfirmed: true
                  });
                  return;
                }

                this.setState({
                  ageError: "Sorry, you must be 19+ to enter."
                });
              }}
            >
              Continue
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default AgeModal;
