import React, { Component } from "react";

import Modal from "react-modal";
import ReactSpinner from "react-spinjs";

import "./Loading.css";

class Loading extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.loading}
        onRequestClose={this.handleClose}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)"
          }
        }}
      >
        <ReactSpinner />
        <div style={{ marginTop: 75 }}>{this.props.status}</div>
      </Modal>
    );
  }
}

export default Loading;
