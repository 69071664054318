import React, { Component } from 'react';

import {
  StripeProvider,
  Elements
} from 'react-stripe-elements';

import CheckoutForm from './components/CheckoutForm';

import Config from '../../_config';

class App extends Component {

  render() {
    return (
      <StripeProvider apiKey={Config.test_mode ? "pk_test_7qA2RtXSAVBoAmAPTxmFtO4h" : Config.stripe_key}>
        <Elements>
          <CheckoutForm
            onCheckoutProcessing={this.props.onCheckoutProcessing}
            onCheckoutComplete={this.props.onCheckoutComplete}
            onError={this.props.onError}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

export default App;
