import React, { Component } from "react";

import "./Error.css";

class Error extends Component {
  render() {
    if (!this.props.message) {
      return null;
    }

    return (
      <div className="errorContainer">
        <span>Error: </span>
        {this.props.message}
      </div>
    );
  }
}

export default Error;
