import React, { Component } from "react";

import { Grid, Row, Col } from "react-bootstrap";
import "./SaleComplete.css";
const { detect } = require("detect-browser");

class SaleComplete extends Component {
  render() {
    const browser = detect();
    var addIEStyle = {};
    if (browser) {
      if (browser.name === "ie") {
        addIEStyle = {
          width: 1000
        };
      }
    }

    return (
      <Grid>
        <Row className="justify-content-center" style={addIEStyle}>
          <Col
            xs={12}
            sm={12}
            md={10}
            lg={12}
            mdOffset={1}
            lgOffset={3}
            className="checkoutContainer"
            style={addIEStyle}
          >
            <div className="saleCompleteContainer">
              <h1>Wine sale is now over...</h1>
              <br />
              <h2>Thanks for participating.</h2>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default SaleComplete;
